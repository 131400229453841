<template>
  <div class="page-users">
    <div class="d-flex align-items-center justify-content-between">
      <div class="title">
        <h3>Users</h3>
        <p>Add, edit or remove users</p>
      </div>

      <CButton color="primary" class="iemq m-0 add-button" @click="addUser">
        Add User <strong class="ml-2">+</strong>
      </CButton>
    </div>

    <div>
      <!--
          striped
          border
          hover
          small
          sorter
          column-filter
          items-per-page-select
          clickable-rows
          @row-clicked="goToUser"
          -->
      <CDataTable
        fixed
        :table-filter="{ placeholder: 'Search user' }"
        :items="users"
        :fields="fields"
        :items-per-page="10"
        :active-page="activePage"
        :pagination="{ doubleArrows: false, align: 'center' }"
        @page-change="pageChange"
      >
        <template #username="data">
          <td class="username-cell ml-3">
            <div
              class="d-flex align-items-center justify-content-start username-field"
              @click.stop="goToUser(data.item)"
            >
              <div class="c-avatar mr-3">
                <img src="img/avatars/default.jpg" class="c-avatar-img " />
              </div>
              <div
                class="d-flex flex-column align-items-start justify-content-center "
              >
                <strong>{{ data.item.username }}</strong>
              </div>
            </div>
          </td>
        </template>

        <template #blocked="data">
          <td>
            <CBadge
              class="status-badge"
              :color="!data.item.blocked ? 'success' : 'warning'"
            >
              {{ data.item.blocked ? "blocked" : "active" }}
            </CBadge>
          </td>
        </template>

        <template #created_at="data">
          <td>
            {{ new Date(data.item.created_at).toDateString() }}
          </td>
        </template>

        <template #actions="data">
          <td class="p-1 px-2 pr-3 actions-cell">
            <CDropdown
              color="white p-3"
              placement="bottom-start"
              :caret="false"
            >
              <template #toggler-content>
                <svg
                  width="4"
                  height="16"
                  viewBox="0 0 4 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                    fill="#333333"
                  />
                </svg>
              </template>
              <CDropdownItem @click.stop="goToUser(data.item)">
                <CIcon name="cil-external-link" class="mr-2" /> Edit
              </CDropdownItem>
              <CDropdownItem
                class="text-danger"
                v-if="!isMe(data.item)"
                @click.stop="tryToDeleteUser(data.item)"
              >
                <CIcon name="cil-trash" class="mr-2" /> Remove
              </CDropdownItem>
            </CDropdown>
          </td>
        </template>
      </CDataTable>
    </div>

    <CModal :show.sync="deleteModal">
      <p>
        Delete this user permanently?
      </p>

      <template #footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <CButton
            @click="cancelDeleteConfirmation"
            color="outline-primary"
            class="iemq mr-3"
          >
            No
          </CButton>
          <CButton
            @click="acceptDeleteConfirmation"
            color="primary"
            class="iemq"
          >
            Yes, I'm sure
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "PUsers",

  data() {
    return {
      selectedUsers: [],
      fields: [
        { key: "username", label: "Username", _classes: "font-weight-bold" },
        { key: "email", label: "Email" },
        { key: "created_at", label: "Created" },
        { key: "blocked", label: "Status" },
        { key: "actions", label: "" },
      ],
      activePage: 1,
      deleteModal: false,
    };
  },

  computed: {
    ...mapState({
      me: (state) => state.auth.user,
      jwtToken: (state) => state.auth.jwt,
      users: (state) => state.user.users,
    }),
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },

  async created() {
    try {
      await this.startLoading();
      await this.fetchUsers();
    } catch (error) {
      console.log({ error });
    } finally {
      await this.stopLoading();
    }
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
      setUsers: "user/setUsers",
    }),

    isMe(user) {
      return user.id === this.me.id;
    },

    async fetchUsers() {
      const usersResponse = await axios.get(`${apiUrl}/users/?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      await this.setUsers(usersResponse.data);
    },

    async deleteUsers() {
      for (const user of this.selectedUsers) {
        await axios.delete(`${apiUrl}/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        });
      }
    },

    goToUser(item) {
      this.$router.push({ path: `users/${item.id}` });
    },

    addUser() {
      this.$router.push({ path: `users/add` });
    },

    tryToDeleteUser(user) {
      this.deleteModal = true;
      this.selectedUsers = [{ ...user }];
    },

    cancelDeleteConfirmation() {
      this.deleteModal = false;
    },

    async acceptDeleteConfirmation() {
      this.deleteModal = false;

      try {
        await this.startLoading();
        await this.deleteUsers();
        await this.fetchUsers();
      } catch (error) {
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-users {
  padding: 32px 24px;

  .add-button {
    strong {
      font-size: 30px;
      font-weight: 100;
    }
  }

  ::v-deep {
    .mfe-2 {
      display: none;

      & + input {
        min-width: 330px;
      }
    }

    .table {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
      border: 0;
      margin: 0;

      thead {
        margin-bottom: 8px;
        margin-top: 19px;

        tr {
          th {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #9d9d9c;

            &:last-of-type {
              max-width: 50px;
            }
          }
        }
      }

      tbody {
        tr {
          background: #ffffff;
          border: 1px solid #e1e1e1;
          box-sizing: border-box;
          margin-bottom: 8px;
          min-height: 80px;

          td {
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            display: block;
            font-size: 15px;
            line-height: 18px;

            .status-badge {
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 18px;
              color: #ffffff;
              padding: 8px;
              border-radius: 38px;
              display: inline-flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              min-width: 100px;
            }

            &.username-cell {
              .c-avatar {
                min-width: 36px;
              }

              .username-field {
                cursor: pointer;
              }
            }

            &.actions-cell {
              padding-top: 6px !important;
              text-align: right;
              overflow: visible;
              max-width: 50px;
            }
          }
        }
      }

      tr {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        td,
        th {
          display: flex;
          flex-flow: row nowrap;
          flex-grow: 1;
          flex-basis: 0;
          min-width: auto;
          border: 0;
          padding: 0 10px;

          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
